"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contact_sheet_model_1 = require("../models/contact-sheet.model");
class contactButtons {
    constructor() {
        this.customScope = undefined;
        this.scope = {
            contact: '=',
            actionsProperties: '=',
            ordering: '='
        };
        this.restrict = 'E';
        this.templateUrl = 'assets/javascripts/xchelper/directives/contactButtons.html';
    }
    handleClick(actionsProperty, action, contact, $event) {
        if (!contact.actions[action].disable) {
            actionsProperty.onClick(contact, $event);
        }
        else {
            $event.stopPropagation();
        }
    }
    link(scope) {
        this.customScope = scope;
        if (this.customScope.contact.actions[contact_sheet_model_1.ContactSheetActionEnum.Video]) {
            this.customScope.contact.actions[contact_sheet_model_1.ContactSheetActionEnum.VideoInvite] = {
                args: ['VideoInvite'],
                disable: false
            };
        }
        if (this.customScope.contact.actions[contact_sheet_model_1.ContactSheetActionEnum.Call]) {
            this.customScope.contact.actions[contact_sheet_model_1.ContactSheetActionEnum.AudioInvite] = {
                args: ['AudioInvite'],
                disable: false
            };
        }
        this.customScope.handleClick = this.handleClick;
    }
}
exports.default = contactButtons;
