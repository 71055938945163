"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CtiStatus_model_1 = require("../../xchelper/models/CtiStatus.model");
class CtiStatusService {
    constructor($rootScope, $window, CtiProxy) {
        var _a, _b;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.CtiProxy = CtiProxy;
        this.currentStatus = '';
        this._ctiStatuses = [];
        (_a = this.$window) === null || _a === void 0 ? void 0 : _a.Cti.setHandler((_b = this.$window) === null || _b === void 0 ? void 0 : _b.Cti.MessageType.CTISTATUSES, this.onCtiStatuses.bind(this));
    }
    set ctiStatuses(_ctiStatuses) {
        this._ctiStatuses = _ctiStatuses;
    }
    get ctiStatuses() {
        return this._ctiStatuses;
    }
    onCtiStatuses(_ctiStatuses) {
        this.ctiStatuses = _ctiStatuses.sort((a, b) => a.status > b.status ? 1 : b.status > a.status ? -1 : 0);
        this.$rootScope.$broadcast('AgentUserStatusesLoaded');
    }
    mapAgentStateToCtiStatus(agentState) {
        switch (agentState) {
            case 'AgentReady':
                return CtiStatus_model_1.CtiStatusEnum.AgentReady;
            case 'AgentOnPause':
                return CtiStatus_model_1.CtiStatusEnum.AgentOnPause;
            case 'AgentLoggedOut':
                return CtiStatus_model_1.CtiStatusEnum.AgentLoggedOut;
            case 'AgentOnWrapup':
                return CtiStatus_model_1.CtiStatusEnum.AgentOnWrapup;
            case 'AgentOnCall':
                return CtiStatus_model_1.CtiStatusEnum.AgentOnCall;
            case 'AgentDialing':
                return CtiStatus_model_1.CtiStatusEnum.AgentDialing;
            case 'AgentRinging':
                return CtiStatus_model_1.CtiStatusEnum.AgentRinging;
            case 'AgentLogin':
                return CtiStatus_model_1.CtiStatusEnum.AgentLogin;
            default:
                return CtiStatus_model_1.CtiStatusEnum.AgentReady;
        }
    }
    mapCtiStatusToAgentState(ctiStatus) {
        return CtiStatus_model_1.CtiStatusEnum[ctiStatus.status];
    }
    switchAgentStateCtiStatus(status, currentStatusName) {
        var _a, _b, _c, _d;
        switch (status) {
            case CtiStatus_model_1.CtiStatusEnum.AgentReady:
                (_a = this.$window) === null || _a === void 0 ? void 0 : _a.Cti.unpauseAgent();
                break;
            case CtiStatus_model_1.CtiStatusEnum.AgentOnPause:
                if (currentStatusName != "" && currentStatusName != null)
                    (_b = this.$window) === null || _b === void 0 ? void 0 : _b.Cti.pauseAgent(undefined, currentStatusName);
                else
                    (_c = this.$window) === null || _c === void 0 ? void 0 : _c.Cti.pauseAgent();
                break;
            case CtiStatus_model_1.CtiStatusEnum.AgentLoggedOut:
                this.CtiProxy.stopUsingWebRtc();
                (_d = this.$window) === null || _d === void 0 ? void 0 : _d.Cti.logoutAgent();
                break;
            default:
                break;
        }
        this.currentStatus = currentStatusName;
    }
    getPossibleCtiStatus(status) {
        switch (status) {
            case CtiStatus_model_1.CtiStatusEnum.AgentOnPause:
                return this.ctiStatuses.filter((elem) => elem.name !== this.currentStatus);
            case CtiStatus_model_1.CtiStatusEnum.AgentOnWrapup:
                return this.ctiStatuses;
            case CtiStatus_model_1.CtiStatusEnum.AgentReady:
            case CtiStatus_model_1.CtiStatusEnum.AgentOnCall:
            case CtiStatus_model_1.CtiStatusEnum.AgentDialing:
            case CtiStatus_model_1.CtiStatusEnum.AgentRinging:
            case CtiStatus_model_1.CtiStatusEnum.AgentLogin:
                return this.ctiStatuses.filter((elem) => elem.status !== CtiStatus_model_1.CtiStatusEnum.AgentReady);
            case CtiStatus_model_1.CtiStatusEnum.AgentLoggedOut:
                return this.ctiStatuses.filter((elem) => elem.status === CtiStatus_model_1.CtiStatusEnum.AgentLoggedOut);
        }
    }
}
exports.default = CtiStatusService;
CtiStatusService.$inject = ["$rootScope", "$window", "CtiProxy"];
