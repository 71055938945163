import { AppType } from "xccti/services/applicationConfiguration.provider";

export default function phoneAppToggler(JitsiProxy, $rootScope, applicationConfiguration) {

  return {
    restrict: 'E',
    scope: {},
    templateUrl: 'assets/javascripts/xchelper/directives/phoneAppToggler.html',
    link: (scope) => {
      scope.showExtendVideoButton = false;
      scope.iframeIsLoaded = false;
      scope.appConfig = applicationConfiguration.getCurrentAppConfig();

      $rootScope.$on(JitsiProxy.JITSI_IFRAME_LOADED, () => {
        scope.iframeIsLoaded = true;
        if (!$rootScope.$$phase) $rootScope.$apply();
      });

      scope.togglePhoneApp = () => {
        JitsiProxy.togglePhoneBar();
      };

      scope.phoneBarIsVisible = () => {
        return JitsiProxy.getShowPhoneBar();
      };

      scope.buttonOnSwitchboard = () => {
        if(scope.phoneBarIsVisible()) {
          return scope.appConfig.appType == AppType.Switchboard ? 'arrow-button-switchboard': '';
        }
      };

      JitsiProxy.subscribeToStartVideo(scope, function videoStarted() {
        scope.showExtendVideoButton = true;
      });

      JitsiProxy.subscribeToCloseVideo(scope, function videoEnded() {
        scope.showExtendVideoButton = false;
        scope.iframeIsLoaded = false;
        if (!$rootScope.$$phase) $rootScope.$apply();
      });
    }
  };
}
