import _ from 'lodash';
import { AppType } from 'xccti/services/applicationConfiguration.provider';

export default function callActionDropdown(applicationConfiguration, XucPhoneState, XucPhoneEventListener, keyNavUtility, $state, CtiProxy, $translate, XucUtils, callContext,$window, JitsiProxy, $log, toast, $uibModal){
  return {
    restrict: 'E',
    templateUrl: 'assets/javascripts/xchelper/directives/callActionDropdown.html',
    scope: {
      contact: "=",
      phoneNumber: "=",
      icon: "@",
      ellipsis: "=",
      emailMaxChars: '@'
    },
    link: (scope) => {
      scope.appConfig = applicationConfiguration.getCurrentAppConfig();
      scope.phoneNumbers = [];

      const init = () => {
        const { contact } = scope;
        if (!_.isObject(contact)) return;

        const { details } = contact;
        const contactsObject = findContactsObject(details);
        if (!contactsObject) return;

        processContactFields(contactsObject.fields);
      };

      const findContactsObject = (details) => details.find(({ name }) => name === 'Contacts');

      const processContactFields = (fields) => {
        Array.isArray(fields) && fields.forEach(processField);
      };

      const processField = ({ data, dataType }) => {
        if (_.isEmpty(data)) return;

        switch(dataType) {
        case "PhoneNumber":
          scope.phoneNumbers.push(data);
          break;
        case "Mail":
          processMailField(data);
          break;
        }
      };

      const processMailField = (email) => {
        scope.email = email;
        generateMailToLink();
        addMailToLinkEventListeners();
      };

      const addMailToLinkEventListeners = () => {
        XucPhoneEventListener.addEstablishedHandler(scope, generateMailToLink);
        XucPhoneEventListener.addReleasedHandler(scope, generateMailToLink);
      };

      scope.isMeetingRoom = () => {
        return callContext.isMeetingRoom(scope.contact);
      };

      scope.isJitsiAvailable = () => {
        return JitsiProxy.jitsiAvailable;
      };

      const generateMailToLink = () => {
        const template = scope.appConfig.mailTemplate;
        const fullName = scope.contact.name;
        const email = scope.email;
        const calls  = XucPhoneState.getCalls();

        const callerNum = _.get(calls.find(call => call.otherDN !== 'anonymous'), 'otherDN');
        const subject = replaceVariables(template.subject, callerNum, fullName);
        const body = replaceVariables(template.body, callerNum, fullName);

        scope.mailToLink = encodeURI(`mailTo:${email}?subject=${subject}&body=${body}`);
      };

      const replaceVariables = (text, callerNum, fullName) => {
        const replace = (text, pattern, value, fallback) => {
          let v = value ? value : `###${$translate.instant(fallback)}###`;
          let r = new RegExp(pattern,"g");
          return text.replace(r, v);
        };

        text = replace(text, '{callernum}', callerNum, 'PLACEHOLDER_NUMBER');
        text = replace(text, '{dstname}', fullName, 'PLACEHOLDER_NAME');
        return text;
      };

      const isNotEmpty = (v) => {
        return !_.isEmpty(v) && !_.isEmpty(v.args[0]);
      };

      init();

      scope.getStateColor = (status) => {
        if (status.video == "Busy") return "user-status-text1";
        return "user-status-text" + status.phone;
      };

      scope.goToChat = (username) => {
        $state.go(scope.appConfig.routing + '.conversation', {remoteParty: username});
      };

      scope.canShowChat = () => {
        return isNotEmpty(scope.contact.actions["Chat"]);
      };

      scope.canShowVideo = () => {
        return isNotEmpty(scope.contact.actions["Video"]);
      };

      scope.canInviteToMeetingRoom = () => {
        if(JitsiProxy.videoIsOngoing()) {
          return isNotEmpty(scope.contact.actions["Video"]);
        }
      };

      scope.inviteToMeetingRoom = (username, displayName) => {
        JitsiProxy.inviteToMeetingRoom(username, displayName);
      };

      scope.startPointToPointVideoCall = (username, displayName) => {
        JitsiProxy.startPointToPointVideoCall(username, displayName);
      };

      scope.canShowDirectTransfer = (calls = XucPhoneState.getCalls()) => {
        return (calls.length == 1 && calls.some(c => c.state == XucPhoneState.STATE_ESTABLISHED)) && scope.appConfig.appType == AppType.Switchboard;
      };

      scope.dropdownStatus = (open, id) => {
        keyNavUtility.stop(open);
        document.getElementById(scope.isMeetingRoom(scope.contact) ? 'call-video-' + id : 'call-' + id).focus();
      };

      scope.canInviteToConference = () => {
        return XucPhoneState.getConferenceOrganizer();
      };

      scope.inviteToConference = (exten) => {
        let conference = XucPhoneState.getConference();
        if (conference != undefined) {
          CtiProxy.conferenceInvite(conference.conferenceNumber, exten, 'User', true);
        }
      };

      scope.generateMeetingShareLink = () => {
        return `${$window.location.origin}${scope.contact.actions.ShareLink.args[0]}`;
      };

      scope.join = () => {
        if(scope.isMeetingRoom()) {
          JitsiProxy.startVideo(scope.contact.actions["Video"].args[0], _meetingroomType(scope.contact)).then(
            XucPhoneState.getCallsNotOnHold().forEach(call => {
              CtiProxy.hold(call.uniqueId);
            })
          ).catch(
            e => {
              $log.error("Cannot start meeting room", e);
            }
          );
        }
      };

      scope.dial = (number) => {
        callContext.normalizeDialOrAttTrans(number);
      };

      const _meetingroomType = (meetingRoomContact) => {
        return meetingRoomContact.isPersonal ? 'personal' : 'static';
      };

      scope.attendedTransfer = function(dst) {
        CtiProxy.attendedTransfer(XucUtils.normalizePhoneNb(dst));
      };

      scope.directTransfer = function (dst) {
        if (dst) CtiProxy.directTransfer(XucUtils.normalizePhoneNb(dst));
      };

      const getTranslationType = function (type) {
        switch (type) {
        case "meetingroom":
          return "MEETINGROOM_COPIED";
        case "phone":
          return "PHONE_COPIED";
        case "email":
          return "EMAIL_COPIED";
        }
      };

      scope.clipboardPopup = function (kind, contact) {
        navigator.clipboard.writeText(contact);
        toast({
          duration: 3000,
          message: $translate.instant(getTranslationType(kind)),
          className: 'alert-copied',
          position: "center",
          container: '.toast-container'
        });
      };

      scope.removePopUpContainer = () => {
        angular.element(document.getElementsByClassName('angularjs-toast')).remove();
      };

      scope.openContactSheet = (contact) => {
        if (document.getElementsByClassName('contact-sheet-modal').length === 0) {
          scope.removePopUpContainer();
          $uibModal.open({
            templateUrl: 'assets/javascripts/xchelper/controllers/contact-sheet.controller.html',
            controller: 'contactSheetController',
            windowClass: 'contact-sheet-modal',
            backdrop: false,
            resolve: {
              contact : () => contact
            },
          }).result.then(() => {}, () => {});
        }

      };
    }
  };
}
