export default function callContext(CtiProxy, XucUtils, XucPhoneState, $rootScope) {

  var _dialOrAttTrans = (normalizedNumber, vars) => {
    $rootScope.$broadcast("EventShowCallPlaceHolder");
    if (XucPhoneState.getCalls().length === 0) {
      CtiProxy.dial(normalizedNumber, vars || {});
    } else {
      CtiProxy.attendedTransfer(normalizedNumber);
    }
  };

  var _isMeetingRoom = (contact) => {
    return contact.isMeetingroom;
  };

  var dialByUsername = (username) => {
    if (XucPhoneState.getCalls().length !== 0) Cti.hold();
    $rootScope.$broadcast("EventShowCallPlaceHolder");
    Cti.dialByUsername(username);
  };

  var _normalizeDialOrAttTrans = (number, vars) => {
    var normalizedNumber = XucUtils.normalizePhoneNb(number);
    if (XucUtils.isaPhoneNb(normalizedNumber)) {
      _dialOrAttTrans(normalizedNumber, vars);
    }
  };

  return {
    dialOrAttTrans: _dialOrAttTrans,
    normalizeDialOrAttTrans: _normalizeDialOrAttTrans,
    dialByUsername: dialByUsername,
    isMeetingRoom: _isMeetingRoom
  };
}
