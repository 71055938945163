import _ from 'lodash';
import { AppType } from './applicationConfiguration.provider';

export default function XucSwitchboard($log, $q, errorModal, XucLink, XucAgentUser, XucQueue, XucUser, XucAgent, applicationConfiguration) {
  let _switchboardIncomingCalls = [];
  let _switchboardHoldCalls = [];
  let incomingQueue;
  let holdQueue;
  let appConfig = applicationConfiguration.getCurrentAppConfig();

  const Errors = {
    ONE_QUEUE_ONLY: '_SWITCHBOARD_AGENT_SHOULD_BE_A_MEMBER_OF_ONE_QUEUE_ONLY',
    HOLD_QUEUE: '_HOLD_QUEUE_NOT_FOUND'
  };

  const _switchboardError = (configurationError, message) => {
    $log.error(message);
    errorModal.showErrorModal(configurationError);
  };

  const _getHoldQueueAsync = (incomingQueueName) => {
    var defer = $q.defer();
    XucQueue.getQueuesAsync().then(allQueues => {
      XucUser.getUserAsync().then(user => XucAgent.getAgentAsync(user.agentId).then(agent => {
        let holdQueue = allQueues.find(queue => queue.name == incomingQueueName + '_hold_' + agent.number);
        if (! angular.isUndefined(holdQueue)) {
          defer.resolve(holdQueue);
        } else {
          holdQueue = allQueues.find(queue => queue.name == incomingQueueName + '_hold');
          if (! angular.isUndefined(holdQueue)) {
            defer.resolve(holdQueue);
          } else {
            defer.reject('Agent must be subscribed to a hold queue');
          }
        }
      }));
    });
    return defer.promise;
  };

  const _getIncomingQueue = () => {
    return incomingQueue;
  };

  const _getHoldQueue = () => {
    return holdQueue;
  };

  const _getSwitchboardIncomingCalls = () => {
    return _switchboardIncomingCalls;
  };

  const _getSwitchboardHoldCalls = () => {
    return _switchboardHoldCalls;
  };

  const _retrieveQueueCall = (queueCall) => {
    Cti.retrieveQueueCall(queueCall);
  };

  const _registerOnSwitchboard = () => {
    XucAgentUser.getQueuesAsync().then(queues => {
      if (queues.length == 1) {
        incomingQueue = queues[0];
        _getHoldQueueAsync(incomingQueue.name).then(queue => {
          holdQueue = queue;
          Cti.subscribeToQueueCalls(incomingQueue.id);
          Cti.subscribeToQueueCalls(holdQueue.id);
          Cti.setHandler(Cti.MessageType.QUEUECALLS, _processQueueCall);
        }, (err) => {
          _switchboardError(Errors.HOLD_QUEUE, err);
        });
      } else {
        _switchboardError(Errors.ONE_QUEUE_ONLY, 'Agent must be logged in one queue only');
      }
    }, (err) => {
      _switchboardError(Errors.ONE_QUEUE_ONLY, err);
    });
  };

  const _processQueueCall = (queueCalls) => {
    if (queueCalls.queueId == incomingQueue.id) {
      _switchboardIncomingCalls.length = 0;
      _.merge(_switchboardIncomingCalls, queueCalls.calls);
    }
    else if (queueCalls.queueId == holdQueue.id) {
      _switchboardHoldCalls.length = 0;
      _.merge(_switchboardHoldCalls, queueCalls.calls);
    }
  };

  var _unInit = function () {
    if (incomingQueue) Cti.unSubscribeToQueueCalls(incomingQueue.id);
    if (holdQueue) Cti.unSubscribeToQueueCalls(holdQueue.id);

    _switchboardIncomingCalls = [];
    _switchboardHoldCalls = [];
    $log.info("Unloading XucSwitchboard service");
    XucLink.whenLogged().then(function () {
      if (appConfig.appType == AppType.Switchboard) {
        _init();
      }
    });
  };

  var _init = function () {
    _registerOnSwitchboard();
    $log.info("Starting XucSwitchboard service");
    XucLink.whenLoggedOut().then(function () {
      _unInit();
    });
  };

  XucLink.whenLogged().then(function () {
    if (appConfig.appType == AppType.Switchboard) {
      _init();
    }
  });

  return {
    getHoldQueueAsync : _getHoldQueueAsync,
    getHoldQueue: _getHoldQueue,
    registerOnSwitchboard: _registerOnSwitchboard,
    processQueueCall: _processQueueCall,
    getSwitchboardIncomingCalls: _getSwitchboardIncomingCalls,
    getSwitchboardHoldCalls: _getSwitchboardHoldCalls,
    retrieveQueueCall: _retrieveQueueCall,
    getIncomingQueue: _getIncomingQueue
  };

}
